@import "../../../theme/colors.scss";

.document-card {
  min-width: 200px;
  max-width: 200px;
  min-height: 250px;
  cursor: pointer;

  &.active {
    .top-part {
      color: white;
      background: white;
    }
    .bottom-part {
      background: map-get($theme-colors, "light");
    }
  }
  .top-part {
    height: 148px;
    background: white;
    font-size: 80px;
    color: map-get($theme-colors, "secondary");
    border-radius: 15px;
    transform-origin: top;
    position: relative;
    font-weight: 500;

    i {
      transition: 300ms;

      color: map-get($theme-colors, "secondary");
    }

    span {
      position: absolute;
      bottom: 4px;
      right: 8px;
      font-size: 13px;
      color: white;
      text-transform: uppercase;
    }
  }

  .bottom-part {
    background: white;
    border-radius: 7px;
    color: map-get($theme-colors, "secondary");
  }

  .top-part,
  .bottom-part {
    transition: 300ms;
  }

  &.active {
    .top-part {
      background: map-get($theme-colors, "secondary");
      i {
        color: white;
      }
    }
    .bottom-part {
      background: map-get($theme-colors, "secondary");
      color: white;
    }
  }
  &.active:hover {
    .top-part {
      background: white !important;
      color: map-get($theme-colors, "secondary");
      //height: 170px;
      .upload-date {
        color: map-get($theme-colors, "secondary");
      }
      i {
        font-size: 100px;
        color: map-get($theme-colors, "secondary");
      }
      span {
        color: white;
      }
    }
    .bottom-part {
      background: white !important;
      color: map-get($theme-colors, "secondary");
    }
  }

  .dropzone {
    &::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      transition: 200ms;
      background: map-get($theme-colors, "tertiary");
      mix-blend-mode: overlay;
      opacity: 0;
      border-radius: 15px;
    }

    &.loading-25::after {
      transform: translateY(75%);
      opacity: 1;
    }
    &.loading-50::after {
      transform: translateY(50%);
      opacity: 1;
    }
    &.loading-75::after {
      transform: translateY(25%);
      opacity: 1;
    }
    &.loading-100::after {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}
