@import "../../../../../theme/colors.scss";

.ask-doc {
  position: fixed;
  z-index: 999;
  bottom: 1%;
  right: 1%;
  height: 40px;
  width: 40px;
  color: map-get($theme-colors, "secondary");
  overflow: hidden;
  transition: 500ms;
  box-shadow: 1px 2px 17px -10px black;
  border-radius: 50%;
  background: white;
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  i {
    font-size: 20px;
  }
  .ask-content {
    width: 0;
    overflow: hidden;

    strong {
      display: block;
      width: 300px;
    }
    p {
      width: 300px;

      font-size: 12px;
    }
  }

  &.open {
    padding: 10px 20px;
    width: 340px;
    height: 180px;
    border-radius: 10px;
    i {
      font-size: 14px;
    }
    .ask-content {
      width: 100%;
    }
  }
}
