.cta-box {
}

.cta-box-image {
  background-repeat: no-repeat;
  background-size: 80% 80%;
  background-position: right 21px;
  transition: transform 500ms;
  transform: translateX(45px);
}
.cta-box:hover .cta-box-image {
  transform: translateX(0px);
}
