@media (max-width: 900px) {
  html,
  body {
    font-size: 12px;

    .form-control {
      font-size: 12px;
    }
  }
  .acc-btn {
    flex-direction: column;
  }
  .user-title-container {
    .user-title-header {
      // display: block !important;
      flex-direction: column !important;
      width: 100%;
      width: 100%;
      text-align: center !important;

      .user-title-icon {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: row !important;
        justify-content: center;
        margin-right: 0 !important;
        .fa {
          margin-right: 5px;
        }
      }
      .user-title-info {
        text-align: center !important;
        width: 92%;
      }
    }
    .user-title-content {
      justify-content: center !important;
      width: 100%;
      margin: 10px 0;
    }
  }
  .nav-tabs .nav-item {
    width: 100% !important;
  }
  .overlay-bottom {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .page-container {
    padding: 0 30px !important;
  }

  #sidebar.active {
    margin-left: -270px !important;

    ul li a {
      top: 40px;

      &.active {
        transform: none !important;
      }
    }
  }

  .top-header {
    padding: 20px 0 8px 0 !important;
    //max-width: 90% !important;
    margin-bottom: 30px !important;

    .top-dropdown.show .avatar-dropdown span {
      padding-left: 10px !important;
      width: auto !important;
    }
    .notif-dropdown.show .avatar-dropdown span {
      padding-left: 10px !important;
      width: auto !important;
    }
  }
  .readonly .input-group {
    width: 100%;
  }
  .readonly label {
    width: 100%;
    margin-top: 10px;
  }
  .page-container {
    max-width: 100% !important;
  }
  h2 {
    font-size: 18px;
    line-height: 22px;
    flex-direction: column !important;
  }
  .title-blue {
    font-size: 22px !important;
    display: block;
    text-align: center;
  }
  table tbody td:not(:first-child):not(:last-child),
  table thead {
    display: none;
  }
  .cta-box {
    margin: 0 auto;
    .top-block {
      height: 72%;
    }
    .bottom-block {
      height: 28%;
    }
  }
  .cta-block {
    justify-content: center;
    align-items: center;
    max-width: 70%;

    .ic-nav {
      display: block;
    }
  }

  .cta-container {
    height: 280px;
    overflow: hidden;
    position: relative;
    top: 52px;
  }
  .mobile-container {
    margin-left: -30px;
    margin-right: -30px;
    padding: 0 15px;
    transition: 300ms;
  }
  .title-blue {
    background: transparent !important;
    font-size: 32px !important;
    margin-top: 20px;
    &.subtitle-blue {
      color: theme-color("tertiary") !important;
    }
  }
  .dash-title h2 {
    color: white !important;
    text-align: center !important;
    margin-top: 2rem !important;

    &.subtitle-blue {
      color: theme-color("tertiary") !important;
    }
  }
  .dash-docs {
    margin-top: 6rem !important;
  }
  .mobile-container {
    &.bk-tertiary {
      background: theme-color("tertiary") !important;
    }
    &.bk-primaryLight {
      background: theme-color-level(primary, -2) !important;
    }
    &.bk-defaultLight {
      background: theme-color-level(default, -2) !important;
    }
    &.bk-default {
      background: theme-color("default") !important;
    }
  }
  .custom-modal-content {
    //width: 100% !important;
    //min-height: 100%;
    .modal-pres {
      box-shadow: none !important;
    }
  }
  .login-container {
    background-image: none;
    background-size: 640px;
    background-position: 57% -8%;
    background-repeat: no-repeat;
    .flip-card-front {
      background: transparent;
    }
    .form-group {
      background: white;
    }
  }
}

@media (max-width: 768px) {
  .page-container {
    padding: 0 30px !important;
  }
}
