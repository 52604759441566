.custom-table {
  overflow: hidden;
  box-shadow: none !important;

  th {
    font-size: 18px;
    color: #b7b7b7;
    padding: 20px 0;
    border-bottom: 0;
  }

  td {
    font-size: 15px;
    font-weight: 800;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
.table thead th {
  vertical-align: bottom;
  color: grey;
  font-weight: 400;
  background: white;
  border: 0px;
}
.table-tr:hover {
  background: white;
  color: var(--secondary);
}
