.navigation-block {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 77%;
    background: #d6d6d6;
  }

  &:not(.first):not(.last) {
    &.active::after {
      background: linear-gradient(90deg, #030ca5 50%, #d6d6d6 50%);
    }

    &.complete::after {
      background: #030ca5;
    }
  }
  &.first::after {
    background: linear-gradient(90deg, transparent 50%, #d6d6d6 55%);
  }
  &.first.complete::after {
    background: linear-gradient(90deg, transparent 50%, #030ca5 50%);
  }
  &.last::after {
    background: linear-gradient(90deg, #d6d6d6 50%, transparent 50%);
  }
  &.last.active::after {
    background: linear-gradient(90deg, #030ca5 50%, transparent 50%);
  }
  &.last.complete::after {
    background: linear-gradient(90deg, #030ca5 50%, transparent 50%);
  }
}

.navigation-block-text {
  font-size: 16px;
}
